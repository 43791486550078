<template>
  <v-container>
    <base-breadcrumb :title="page.title" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card heading="Editar usuário">
      <FormUserSteps
        v-if="!dataIsLoading"
        :isEdit="true"
        :userData="value"
        @save="update"
      />
      <v-skeleton-loader
        v-else
        class="mb-6"
        boilerplate="true"
        elevation="0"
        type="article"
      ></v-skeleton-loader>
    </base-card>
  </v-container>
</template>

<script>
import collect from 'collect.js'
import { alertMessages } from '@/constants/messages'
import { roles } from '@/constants/roles'
import { mapMutations } from 'vuex'

export default {
  name: 'EditUser',
  data: () => ({
    page: {
      title: 'Editar usuário'
    },
    value: {},
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Usuários',
        disabled: false,
        to: '/users'
      },
      {
        text: 'Editar usuário',
        disabled: true
      }
    ],
    organizationId: '',
    userId: '',
    peopleId: '',
    alertMessages,
    roles,
    dataIsLoading: false
  }),
  mounted () {
    this.userId = this.$route.params.userId
    this.peopleId = this.$route.params.peopleId
    this.organizationId = localStorage.getItem('ACTIVE_ORGANIZATION')
    this.getUser()
  },
  methods: {
    ...mapMutations(['setLoading']),
    handleError (error = null) {
      collect(error.errors).each((msg) => {
        this.$store.dispatch('alert', { color: 'red', msg: msg[0] })
      })
    },
    getUser () {
      this.dataIsLoading = true
      this.$axios
        .get(`/organizations/${this.organizationId}/users/${this.userId}`)
        .then((response) => {
          this.value = response.data.data
          const acls = collect(response.data.data.acl)
            .where('organization_id', this.organizationId)
            .collapse()
            .toArray()
          this.value.acl = this.formatAcls(acls)
        })
        .finally(() => {
          this.dataIsLoading = false
        })
    },
    formatAcls (acls) {
      return {
        organizationAcl: collect(acls)
          .where('type', 'ORGANIZATION')
          .pluck('roles')
          .collapse()
          .toArray(),
        courseAcl: collect(acls)
          .where('type', 'COURSE')
          .map((value) => {
            return {
              role: value.roles,
              school: value.school_id,
              course: value.course_id,
              course_name: value.rostered_in_name
            }
          })
          .all(),
        schoolAcl: collect(acls)
          .where('type', 'SCHOOL')
          .map((value) => {
            return {
              role: value.roles,
              school: value.school_id
            }
          })
          .all()
      }
    },
    update (value) {
      this.setLoading(true)
      const payload = { name: value.user.name }
      this.$axios
        .patch(`/organizations/${this.organizationId}/users/${this.userId}`, payload)
        .then(async () => {
          await this.updateUserInOrganization(value.org_roles)
          if (value.school_roles.length) {
            await this.updateUserInSchool(value.school_roles)
          }
          if (value.course_roles.length) {
            await this.updateUserInCourse(value.course_roles)
          }
        })
        .then(() => {
          this.$store.dispatch('alert', {
            msg: this.alertMessages.USER_UPDATED,
            color: 'green'
          })
          this.gotToListUsers()
        })
        .catch((error) => this.handleError(error.response.data))
        .finally(() => {
          this.setLoading(false)
        })
    },
    async updateUserInOrganization (acl) {
      const roles = collect(this.roles).map((role) => {
        return {
          name: role.name,
          operation: acl.includes(role.name) ? 'ADD' : 'REMOVE'
        }
      })
      const payload = {
        users: [
          {
            user: { id: this.userId },
            roles: roles
          }
        ]
      }
      await this.$axios.post(
        `/organizations/${this.organizationId}/people/sync`,
        payload
      )
    },
    async updateUserInSchool (acl) {
      collect(acl).map(async value => {
        const payload = {
          users: [{
            user: { id: this.userId },
            roles: collect(value.role).map(role => ({ name: role, operation: value.operation }))
          }]
        }
        await this.$axios.post(`/organizations/${this.organizationId}/schools/${value.school}/people/sync`, payload)
      })
    },
    async updateUserInCourse (acl) {
      collect(acl).map(async value => {
        const payload = {
          users: [{
            user: { id: this.userId },
            roles: collect(value.role).map(role => ({ name: role, operation: value.operation })).all()
          }]
        }
        await this.$axios.post(`/organizations/${this.organizationId}/schools/${value.school}/courses/${value.course}/people/sync`, payload)
      })
    },
    cancel () {
      this.gotToListUsers()
    },
    gotToListUsers () {
      this.$router.push({ path: '/users' })
    }
  }
}
</script>
